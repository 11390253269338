<template>
<div>
  <v-row>
    <v-col cols="12" md="10" lg="8" class="ma-auto pa-0 pb-5">
        <v-btn class="ma-auto pa-0 custom-color-accent" style="width:100%" depressed @click="print_invoice()"> 
          <span>{{$t("print")}}</span>
        </v-btn> 
    </v-col>
    <v-col cols="12" md="10" lg="8" class="my-border white ma-auto pa-0" v-if="currentInvoice.address" style="position:relative">
          <v-row class="pa-4 ma-auto" ref="content">
            <v-col cols="12" class="ma-0 px-0 py-6">
               <v-img
                  height="auto"
                  width="30%"
                  class="float-left"
                  :src="require('@/assets/gov-of-canada.jpg')"
                ></v-img>
            </v-col>
            <v-col cols="12" class="text-left text--secondary font-weight-medium">
              <span>{{$t('invoice')}}#: </span><span class="font-weight-regular">{{ invoiceId }}</span>
            </v-col>
            <v-col cols="12" class="mb-8 text-left text--secondary font-weight-medium text-capitalize">
              <span>{{$t("datecreated")}}: </span><span class="font-weight-regular">{{today | currentDate }}</span>
            </v-col>
            <v-col cols="6" class="text-left text--secondary font-weight-medium">
              <span class="text-capitalize">{{currentInvoice.address.CompanyAgency}}</span><br> {{currentInvoice.address.AddrNum}} {{currentInvoice.address.Address}}
              <br>{{currentInvoice.address.City}}, {{currentInvoice.address.Province}} {{currentInvoice.address.PostalCode}}
            </v-col>
            <v-col cols="6" class="text-left text--secondary font-weight-medium text-capitalize">
              <span class="text-capitalize">Business</span><br> Dan Gagliardi<br> Dan@softsim.ca
            </v-col>
            <v-col cols="12" class="mt-8 text-left text--secondary font-weight-medium text-capitalize">
              <v-simple-table class="white">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="customlightgray">{{$t("Fee Type")}}</th>
                            <th class="customlightgray">{{$t("paymethod")}}</th>
                            <th class="customlightgray">{{$t("unitprice")}}</th>
                            <th class="customlightgray">{{$t("quantity")}}</th>
                            <th class="customlightgray">{{$t("price")}}</th>
                        </tr>
                    </thead>
                    <tbody class="text--secondary font-weight-regular">
                        <tr
                        v-for="item in currentInvoice.fees"
                        :key="item.fee_type"
                        >
                          <td><span>{{getLookup('Fees',item.fee_type)[0]['text']}}</span></td>
                          <td>{{item.payMethod}}</td>
                          <td><span>{{item.unitPrice | currency}}</span>{{currencies.filter(i=>i.currency==item.currency)[0].code}}</td>
                          <td><span>{{parseInt(item.quantity)}}</span></td>
                          <td class="text-right font-weight-medium">{{ item.unitPrice * item.quantity | currency }}{{c=currencies.filter(i=>i.currency==item.currency)[0].code}}</td>
                        </tr>
                         <tr class="font-weight-medium">
                            <td colspan="3"></td>
                            <td colspan="2"><span>{{$t("Total")}}:</span><span class="float-right">{{ total | currency }}{{c}}</span></td>
                          </tr>
                    </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <div class="overlay d-flex align-center justify-center" v-if="load">
            <v-progress-circular class="d-flex align-center"
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </div>
    </v-col>
    <v-col cols="12" md="10" lg="8" class="ma-auto pa-0 pt-5">
        <v-btn class="ma-auto pa-0 custom-color-accent" style="width:100%" depressed @click="print_invoice()"> 
          <span>{{$t("print")}}</span>
        </v-btn> 
    </v-col>
  </v-row>   
</div>
</template>

<script>
import axcessApi from '@/plugins/axcessApi'
import { CURRENCY } from "@/helpers/exports";
import jsPDF from "jspdf";
import html2canvas from "html2canvas"
import { mapState,mapActions } from 'vuex';
export default {
  data(){
    return {
      invoiceId: null,
      currentInvoice:{},
      load:false,
      today: Date(),
      currencies: CURRENCY,
      c:'$',
    }
  },
  async created () {
    //this.$store.dispatch('request/retriveRequestItem', parseInt(this.$route.params.id) );
    this.currentInvoice = await axcessApi.getRequest('request/'+ parseInt(this.$route.params.id) ).then(r=>r.data);
    this.retriveConfigurations();
  },
  mounted() {
    this.invoiceId = parseInt(this.$route.params.id);
  },
  computed:{
      total() {
        if(this.currentInvoice.fees){
          return this.currentInvoice.fees.reduce(
              (acc, item) => acc + item.unitPrice * item.quantity,
              0
          );
        }
        return 0
    },
    ...mapState('configuration',{
      configurations:'configurations'
    })

  },

  filters:{
    currency(value) {
      return value.toFixed(2);
    }
  },
  methods: {
    ...mapActions('configuration',['retriveConfigurations']),
    getLookup(column, val='') {
      return this.configurations
          .filter((filter) => {
            if(val){
              return filter.LookUpName === column && filter.LookUpKey === val
            }else{
              return filter.LookUpName === column
            }
          })
          .map((item) => {
            let arr = [];
            arr["text"] =
                this.$vuetify.lang.current === "en"
                    ? item.LookUpStringE
                    : item.LookUpStringF;
            arr["value"] = item.LookUpKey;
            return arr;
          });
    },  
    print_invoice(){
      this.load=true;
      let canvasElement = document.createElement('canvas');
      html2canvas(this.$refs.content, { canvas: canvasElement }).then((canvas)=>{
        var doc = new jsPDF('p', 'pt','a4',true);
        var imgData = canvas.toDataURL('image/png');
        var imgWidth = doc.internal.pageSize.getWidth(); 
        var pageHeight = doc.internal.pageSize.getHeight();
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        var position = 0;
        var mx=10;
        doc.addImage(imgData, 'PNG', mx, position, imgWidth-mx*2, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', mx, position, imgWidth-mx*2, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save( this.currentInvoice.reqCode+'-Invoice.pdf');
        this.load=false;
      });
    }
  },
}
</script>

<style lang="scss">
.overlay{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

</style>